@import url("https://fonts.googleapis.com/css2?family=Muli&display=swap");



/* colour change  */
/* body { */
  /* margin: 0;
  padding: 0;
  background-image: url('../src/images/bg2.jpg');
  background-size: cover; 
  background-repeat: no-repeat; 
  background-position: center;
} */
/* colour change  */



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}



.navbar {
  background-color: #fff !important;
}

.navbar-brand {
  font-size: 1.9rem !important;
  color: #565387 !important;
}

.navbar a {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

.navbar a:hover {
  color: #3498db !important;
  text-decoration: underline !important;
}




@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}

/* ==========================================================================
#Main Landing Section
========================================================================== */

#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #3498db;
}

#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/* ////////Contact ////////////
 */

.contact_div {
  width: 100%;
  height: 79vh;
}

/* /// footer///////////////////// */
footer p {
  padding: 0;
  margin: o;
}

/* responsive code */

@media (max-width: 991px) {
  #header {
    height: 75vh;
    text-align: center;
  }

  #header .header-img {
    text-align: center;
  }
  #header .header-img img {
    width: 27%;
  }
}

@media (max-width: 768px) {
  #header {
    margin-top: 20px;
  }
  #header h1 {
    font-size: 28px;
  }
  #header h2 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  #header .header-img img {
    width: 70%;
  }
}


/* service card  */
.card-3d {
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

.in-view .card-3d,
.card-3d:hover {
  transform: rotateY(10deg) rotateX(10deg) scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
/* service card  */

/* contact form  */
.contact-form .form-control {
  border-radius: 0.25rem;
  border: 2px solid #ced4da;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form .form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.contact-form .btn-outline-primary {
  border-radius: 0.25rem;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.contact-form .btn-outline-primary:hover {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.contact-form .alert {
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.contact-form {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-form:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}


/* contact form  */


/* enroll.css */
.enroll-form .form-control {
  border-radius: 0.25rem;
  border: 2px solid #ced4da;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.enroll-form .form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.enroll-form .btn-outline-primary {
  border-radius: 0.25rem;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

.enroll-form .btn-outline-primary:hover {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.enroll-form .alert {
  border-radius: 0.25rem;
  margin-top: 1rem;
}

.enroll-form {
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.enroll-form:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* enroll.css */


/* new  */
/* Add to index.css or a separate CSS file */

.link-container {
  margin: 20px 0;
}

.btn-custom {
  background: linear-gradient(145deg, #0056b3, #003d7a);
  /* border: 2px solid; */
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), inset 0 2px 5px rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  
}

.btn-about {
  background: linear-gradient(145deg, #0056b3, #003d7a);
}

.btn-map {
  background: linear-gradient(145deg, #0056b3, #003d7a);
}

.btn-custom:hover {
  background: linear-gradient(145deg, #007bff,#007bff );
  /* border-color: #004080; */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), inset 0 2px 5px rgba(255, 255, 255, 0.4);
  transform: translateY(-2px);
}

.btn-custom:active {
  background: linear-gradient(145deg, #4caf50, #388e3c);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 1px 3px rgba(255, 255, 255, 0.2);
  transform: translateY(0);
}


/* footer  */
.footer {
  width: 100%;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  color: #343a40;
  text-align: center;
  padding: 20px 0;
  position: relative;
  bottom: 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-logo h2 {
  font-size: 24px;
  margin: 0;
}

.footer-links a {
  color: #343a40;
  margin: 0 10px;
  text-decoration: none;
  font-weight: 500;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-social-media a {
  color: #343a40;
  margin: 0 10px;
  font-size: 20px;
  transition: color 0.3s;
}

.footer-social-media a:hover {
  color: #007bff;
}

.footer-bottom {
  margin-top: 20px;
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  font-size: 14px;
}


/* navbar logo  */


.navbar-brand:hover i {
  color: #007bff;
}


/* freq asked */
.faq-section p,
.accordion {
    width: 75%;
}

.accordion .accordion-button:focus,
.accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion .accordion-button:not(.collapsed) {
    background-color: #007bff;
    color: #ffffff;
}

.accordion .accordion-button:not(.collapsed)::after {
    filter: invert();
}

@media only screen and (max-width: 992px) {
    .faq-section p,
    .accordion {
        width: 100%;
    }
}

/* know more  */
.photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.photo-3d {
  width: 90%;
  margin: 15px 0;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  border-radius: 10px;
}

.photo-3d:hover {
  transform: rotateY(15deg) rotateX(15deg);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
}
